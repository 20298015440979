import { useState, useEffect } from 'react';
import './WeatherData.css';
import RenderClock from './RenderClock';

function WeatherData() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [setErrorWakeLock] = useState(null);

  useEffect(() => {
    const videoElement = document.createElement('video');
    videoElement.src = '/muted.mp4';
    videoElement.loop = true;
    videoElement.muted = true;
    videoElement.play();
    wakeLock();
    setMainFontSize();
    window.addEventListener('resize', setMainFontSize);
    setIsLoading(true);
    fetchData();
    setInterval(
      () => {
        fetchData();
      },
      1000 * 60 * 15,
    );
  }, []);

  function setMainFontSize() {
    document.querySelector('.main').style.fontSize =
      `${(Math.min(window.innerWidth, window.innerHeight) / 768) * 25}px`;
  }

  async function wakeLock() {
    let wakeLock = null;
    try {
      wakeLock = await navigator.wakeLock.request('screen');
      setErrorWakeLock('Wake Lock is active');
    } catch (err) {
      setErrorWakeLock(`${err.name}, ${err.message}`);
    }
  }

  function cleanForecast(forecast) {
    const hours = [];
    return forecast.filter((obj) => {
      const f = hours.indexOf(obj.h) === -1;
      hours.push(obj.h);
      return f;
    });
  }

  function fetchData() {
    var xhr = new XMLHttpRequest();
    if (window.location.host.indexOf('192.168') > -1) {
      xhr.open('GET', 'https://arso.rifelj.com/api/weather/Ptuj', true);
    } else {
      xhr.open('GET', '/api/weather/Ptuj', true);
    }

    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        try {
          var response = JSON.parse(xhr.responseText);
          const data = {
            ...response.data,
            forecast: groupTodaysAndForecast(response.data.todays, cleanForecast(response.data.forecast)),
          };
          setData(data);
          document.querySelector('.main').style.visibility = 'visible';
        } catch (e) {
          console.log('ERROR--------->', e);
        }
      } else if (xhr.status === 401 || xhr.status === 403) {
        // Redirect to Google authentication
        console.log('Redirecting to Google authentication');
        window.location.href = '/auth/google';
      } else {
        console.log('Unknown error', xhr.status);
      }
      setIsLoading(false);
    };

    xhr.send();
  }

  function groupTodaysAndForecast(todays, forecast) {
    const todaysFiltered = todays.filter((obj) => forecast.map((x) => x.h).indexOf(obj.h) === -1);
    return [...todaysFiltered, ...forecast];
  }

  function localHour(hour) {
    const offsetInHours = new Date().getTimezoneOffset() / 60;
    return (parseInt(hour) - offsetInHours + 24) % 24;
  }

  function renderHour(hour, values, index, minTemp, maxTemp) {
    const value = values.find((obj) => obj.h.substring(11) === hour);
    const classes = ['cell'];
    if (!value) {
      return <div className="cell" key={`hour-${hour}-${index}`}></div>;
    }
    if (value.h < new Date().toISOString().substring(0, 13)) {
      classes.push('disabled');
    }
    return (
      <div className={classes.join(' ')} key={`hour-${hour}-${index}`}>
        {value && (
          <>
            <div className="hour">{localHour(hour)}:00</div>
            <div className="temperature">
              <div
                className={`temperature-value ${parseInt(value.t) === minTemp && 'temp-blue'} ${parseInt(value.t) === maxTemp && 'temp-yellow'}`}
              >
                {value.t}°
              </div>
            </div>
            <div className="icon-cell">
              <img src={`/svg/${value.i}.svg`} className="icon" />
            </div>
            <div className="wind">
              {value.s && <img src={`/svg/${value.s}.svg`} className="wind-icon" />}
              {value.w}
            </div>
          </>
        )}
      </div>
    );
  }

  function getSlovenianWeekday(date) {
    const weekdays = ['NED', 'PON', 'TOR', 'SRE', 'ČET', 'PET', 'SOB'];
    return weekdays[date.getDay()];
  }

  function getFullSlovenianWeekday(date) {
    const weekdays = ['Nedelja', 'Ponedeljek', 'Torek', 'Sreda', 'Četrtek', 'Petek', 'Sobota'];
    return weekdays[date.getDay()];
  }

  function renderDay(offsetDay) {
    const date = new Date(offsetDay);
    return (
      <div className="day-row cell" key={`day-${offsetDay}`}>
        <div className="move-left">
          <div className="day-text">
            {getSlovenianWeekday(date)} {date.getDate() + '.' + (date.getMonth() + 1) + '.'}
          </div>
        </div>
      </div>
    );
  }

  function showRows(dayOffset) {
    let everyNHour = 3;
    if (dayOffset === 0) {
      everyNHour = 2;
    }
    if (!data) {
      return null;
    }
    const today = new Date();
    const tomorrow = new Date(today);
    const offsetDay = new Date(tomorrow.setDate(today.getDate() + dayOffset)).toISOString().split('T')[0];
    let minTemp = 100;
    let maxTemp = -100;
    const values = data.forecast
      .filter((obj) => {
        return obj.h.substring(0, 10) === offsetDay && parseInt(obj.h.substring(11)) % everyNHour === 0;
      })
      .sort((a, b) => parseInt(a.h.substring(11)) - parseInt(b.h.substring(11)));
    const day = [renderDay(offsetDay)];
    return day.concat(
      [...Array(24 / everyNHour)]
        .map((_, idx) => {
          if (values[idx]) {
            if (parseInt(values[idx].t) < minTemp) {
              minTemp = parseInt(values[idx].t);
            }
            if (parseInt(values[idx].t) > maxTemp) {
              maxTemp = parseInt(values[idx].t);
            }
          }
          return (idx * everyNHour).toString().padStart(2, '0');
        })
        .map((h, index) => renderHour(h, values, index, minTemp, maxTemp)),
    );
  }

  function renderCurrentTemperature() {
    if (data && data.current) {
      return <div className="current-weather-temperature__value">{data.current.t}°</div>;
    }
    return null;
  }

  function renderCurrentWeatherIcon() {
    if (data && data.current) {
      return (
        <div className="current-weather-icon-wrapper">
          <img src={`/svg/${data.current.i}.svg`} className="current-weather-icon" />
        </div>
      );
    }
    return null;
  }

  function renderSunriseSunset() {
    if (data && data.current) {
      return (
        <div className="sunrise-sunset">
          <div>
            <img src="/svg/sun.svg" className="sun-icon" />
            <span>{data.current.v}</span>
          </div>
          <div>
            <img src="/svg/moon.svg" className="moon-icon" />
            <span>{data.current.z}</span>
          </div>
        </div>
      );
    }
  }

  function renderDayForecast(day) {
    if (!data || !data.daysForecast) {
      return null;
    }
    const date = new Date(day.d);
    return (
      <div className="day-forecast" key={day.d}>
        <div className="day-forecast__day">
          {getSlovenianWeekday(date)} {date.getDate() + '.' + (date.getMonth() + 1) + '.'}
        </div>
        <div className="day-forecast__bottom">
          <div className="day-forecast__bottom__temperatures">
            <div className="temp-yellow">{day.h}°</div>
            <div className="temp-blue">{day.l}°</div>
          </div>
          <div className="day-forecast__bottom__weather">
            <div className="forecast-day-icon">
              <img src={`/svg/${day.i}.svg`} className="icon" />
            </div>
            <div className="flex-row">
              {day.v}
              <img src={`/svg/${day.s}.svg`} alt="Clear day" className="wind-icon" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderDaysForecast() {
    if (data && data.daysForecast) {
      return data.daysForecast
        .filter((day) => {
          const today = new Date();
          const d = day.d;
          const afterTomorrow = new Date(today.setDate(today.getDate() + 2)).toISOString().substring(0, 10);
          return d > afterTomorrow;
        })
        .filter((_, idx) => {
          return idx <= 5;
        })
        .map(renderDayForecast);
    }
  }

  function renderDayAndDate() {
    if (data && data.current) {
      return (
        <div className="day-and-date">
          {getFullSlovenianWeekday(new Date())}, {new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.'}
        </div>
      );
    }
  }

  function renderCurrentWind() {
    if (data && data.current) {
      return (
        <div className="current-wind">
          <img src={`/svg/${data.current.s}.svg`} className="wind-icon" />
          {data.current.w}
        </div>
      );
    }
  }

  function renderCurrentHumidity() {
    if (data && data.current) {
      return (
        <div className="current-humidity">
          <img src="/svg/humidity.svg" className="humidity-icon" />
          {data.current.r}
        </div>
      );
    }
  }

  function renderAll() {
    if (isLoading) {
      return <div></div>;
    }
    return (
      <>
        <div className="header__right">
          <div>{renderSunriseSunset()}</div>
          <div className="header__today">
            <div className="current-temperature-and-icon">
              {renderCurrentTemperature()}
              {renderCurrentWeatherIcon()}
              <div className="wind-and-humidity">
                {renderCurrentWind()}
                {renderCurrentHumidity()}
              </div>
              <div className="settings">
                <img src="/svg/cogwheel.svg" />
              </div>
            </div>
          </div>
        </div>
        <div className="main__header">
          <div className="clock-wrapper">
            <RenderClock />
          </div>
          {renderDayAndDate()}
        </div>
        <div>
          <div className="main-table">
            <div>{showRows(0)}</div>
          </div>
          <div className="main-table">
            <div>{showRows(1)}</div>
          </div>
          <div className="main-table">
            <div>{showRows(2)}</div>
          </div>
        </div>
        <div className="bottom">
          <div className="bottom__forecast">{renderDaysForecast()}</div>
        </div>
      </>
    );
  }

  return (
    <div className="main">
      {/* <div
        style={{
          display: 'none',
          float: 'left',
          position: 'absolute',
          top: '50px',
          left: '40px',
          color: 'green',
          padding: '3px 6px',
        }}
      >
        {window.screen.width}x{window.screen.height} {errorWakeLock}
      </div> */}
      {renderAll()}
    </div>
  );
}

export default WeatherData;
